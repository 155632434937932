<!-- {{toSave|json}} -->
<div class="formEdit">
    <mat-form-field appearance="fill">
        <mat-label>Inizio</mat-label>
        <input matInput [min]="minDate" style="font-weight: bolder; color: black;" [ngxMatDatetimePicker]="picker" placeholder="" [formControl]="dateControlStart" disabled>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker disabled="false" #picker color="primary"></ngx-mat-datetime-picker>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Fine</mat-label>
        <input matInput [min]="minDate" style="font-weight: bolder; color: black;" [ngxMatDatetimePicker]="picker2" placeholder="" [formControl]="dateControlEnd" disabled>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker disabled="false" #picker2 color="primary"></ngx-mat-datetime-picker>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="users">
        <mat-label>Scegli capo squadra</mat-label>
        <mat-select [(ngModel)]="userChoiche">
          <mat-option *ngFor="let user of users" [value]="user">{{user.usrEmail}}</mat-option>
        </mat-select>
    </mat-form-field> 
    <mat-form-field appearance="fill" *ngIf="anagrafiche">
        <mat-label>Scegli cliente</mat-label>
        <mat-select [(ngModel)]="anagraficaChoiche">
            <mat-option *ngFor="let anagrafica of anagrafiche" [value]="anagrafica">{{anagrafica.anaRagioneSociale}} {{anagrafica.anaSurname}} {{anagrafica.anaName}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
    <mat-label>Dipendenti</mat-label>
        <mat-select [formControl]="toppings" multiple>
            <mat-option *ngFor="let dipendente of dipendenti" [value]="dipendente">{{dipendente.dipCognome}} {{dipendente.dipNome}}</mat-option>
        </mat-select>
    </mat-form-field>
    Note (Max 500 caratteri)
    <textarea maxlength="500" style="height: 120px;" [(ngModel)]="note">{{note}}</textarea>
    <br><br>
    <div class="buttons">
        <button mat-raised-button color="primary" (click)="saveData()">SALVA MISSIONE</button>
        <button mat-raised-button (click)="close()">ANNULLA E CHIUDI</button>
        <button mat-raised-button color="warn" (click)="deleteMission()">ELIMINA</button>
    </div>
</div>
