import { RapAttRapportiniAttrezzature } from "./RapAttRapportiniAttrezzature";
import { RapdescRapportiniDescrizioni } from "./RapdescRapportiniDescrizioni";
import { RapRapportini } from "./RapRapportini";
import { RapumRapportiniUm } from "./RapumRapportiniUm";

export class RapRapportiniFleet {
    rapRapportini: RapRapportini;
    rapAttRapportiniAttrezzature: RapAttRapportiniAttrezzature[];
    rapdescRapportiniDescrizioni: RapdescRapportiniDescrizioni[];
    rapumRapportiniUm: RapumRapportiniUm[];
}