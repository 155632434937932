<div class="bodyContent">
    <div class="topper">
        <h1>Attrezzatura</h1>
        <button class="fab-add" mat-fab color="primary" (click)="addElement()"><mat-icon>add</mat-icon></button>
    </div>
    <mat-form-field>
      <mat-label>Cerca...</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
   </mat-form-field>      
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
      
        <!-- Position Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Attrezzatura </th>
          <td mat-cell *matCellDef="let element"> {{element.attAttrezzatura}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="password">
          <th mat-header-cell *matHeaderCellDef> Costo orario </th>
          <td mat-cell *matCellDef="let element"> {{element.attCostoOrario | currency: 'EUR'}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="enable">
          <th mat-header-cell *matHeaderCellDef> Tipologia </th>
          <td mat-cell *matCellDef="let element">
            <label *ngIf="element.attTipoAttrezzatura === 3">Km</label>
            <label *ngIf="element.attTipoAttrezzatura === 2">Ore</label>
            <label *ngIf="element.attTipoAttrezzatura === 1">Unità</label>
            <label *ngIf="element.attTipoAttrezzatura === 0">Litri</label>
          </td>
        </ng-container>   
           
      
        <!-- Weight Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Azioni </th>
          <td mat-cell *matCellDef="let element">
            <button color="primary" mat-raised-button (click)="editElement(element)"><mat-icon>edit</mat-icon></button>&nbsp;
            <button color="warn" mat-raised-button (click)="deleteElement(element)"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>      
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Carico..</td>
        </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
</div>