<div class="formEdit">
    <mat-form-field appearance="fill">
        <mat-label>Cognome</mat-label>
        <input matInput [(ngModel)]="data.dipCognome" placeholder="">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Nome</mat-label>
        <input matInput [(ngModel)]="data.dipNome" placeholder="">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Costo orario</mat-label>
        <input matInput [(ngModel)]="data.dipCostoOrario" placeholder="">
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="saveData()">SALVA</button>
    <br/>
    <button mat-raised-button (click)="close()">Annulla e chiudi</button>
</div>
