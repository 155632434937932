export class MisMissioni {
    misId: number;
    misDateTime: Date | string;
    misDateStart: Date | string;
    misDateEnd: Date | string;
    misDipendenti: string;
    misCustomer: number;
    misCapoSquadra: number;
    misCustomerName: string;
    misDipendentiName: string;
    misRapId?: number;
    misSendEmail: string;
    misSendEmailDate: Date | string;
    misNote: string;
    misNoteCantiere: string;
    misIndirizzo: string;
}