import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EditAgendaComponent } from '../modals/edit-agenda/edit-agenda.component';
import { EditNotecantiereComponent } from '../modals/edit-notecantiere/edit-notecantiere.component';
import { EditReportComponent } from '../modals/edit-report/edit-report.component';
import { PrintconvalidaComponent } from '../modals/printconvalida/printconvalida.component';
import { AnaAnagrafica } from '../objects/AnaAnagrafica';
import { MisMissioni } from '../objects/MisMissioni';
import { UsrUser } from '../objects/UsrUser';
import { ApiService } from '../services/api.service';
import { LoadingService } from '../services/loading.service';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {

  users: UsrUser[]

  dateControlStart = new FormControl(new Date());
  dateControlEnd = new FormControl(new Date());
  @ViewChild('picker1') picker1: any;
  @ViewChild('picker2') picker2: any;


  allMissionsGotIt: MisMissioni[]


  constructor(
    private loadingService: LoadingService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private snackBarService: SnackbarService
  ) { 
    this.getAllUsers();

    let today = this.datePipe.transform(new Date(), 'yyyy-MM-ddT05:00:00');
    let sevendays = this.datePipe.transform(new Date().setDate(new Date().getDate() + 7), 'yyyy-MM-ddT00:00:00');

    this.dateControlStart.setValue(today)
    this.dateControlEnd.setValue(sevendays)

    this.getMissioniBetweenDate()
    // this.openConvalida(new MisMissioni()) // TODO togliere quando sono finiti i test altrimenti apre in continuazione il printconvalida component

  }

  openSetAgenda(mission: MisMissioni) {
    const dialogRef = this.dialog.open(EditAgendaComponent, {
      width: '80%',
      data: mission
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
      this.getMissioniBetweenDate();
    });
  }
 
  getAllUsers() {
    this.loadingService.showLoading();
    this.apiService.getAllUsers()
    .then((users: UsrUser[]) => {
      this.users = users.filter(x => x.usrType == 1 && x.usrEnable == true);
      console.log(this.users)
      this.loadingService.hideLoading();  
    })
  }

  getMissioniBetweenDate(){
    let start = this.datePipe.transform(this.dateControlStart.value, 'yyyy-MM-ddTHH:mm:ss');
    let end = this.datePipe.transform(this.dateControlEnd.value, 'yyyy-MM-ddTHH:mm:ss');

    this.loadingService.showLoading();
    this.apiService.getMissioniByDate(start, end)
    .then((missioni: MisMissioni[]) => {
      console.log('missioni get: ', missioni)
      this.allMissionsGotIt = missioni;
      this.loadingService.hideLoading();
    }, err => {
      console.log('Error: ', err);
      this.loadingService.hideLoading();
    })
  }

  getMissionFiltered(user: UsrUser){
    return this.allMissionsGotIt.filter(x => x.misCapoSquadra == user.usrId);
  }

  editMission(mission: MisMissioni){
    console.log(mission)
    this.openSetAgenda(mission)
  }

  editReport(mission: MisMissioni){
    const dialogRef = this.dialog.open(EditReportComponent, {
      width: '80%',
      data: mission
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMissioniBetweenDate();
    });
  }

  openConvalida(mission: MisMissioni){

    this.apiService.getAnagraficaById(mission.misCustomer)
    .then((an: AnaAnagrafica) => {
      const emailAnagrafica = an.anaEmail
      const dialogRef = this.dialog.open(PrintconvalidaComponent, {
        width: '60%',
        data: [
          mission,
          emailAnagrafica          
        ]
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.getMissioniBetweenDate();
      });
    })
  }

  ngOnInit(): void {
  }

  editNoteCantiere(mis: MisMissioni) {
    const dialogRef = this.dialog.open(EditNotecantiereComponent, {
      width: '60%',
      data: mis
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMissioniBetweenDate();
    });

   
  }

}
