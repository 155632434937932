export class RapdescRapportiniDescrizioni {
    rapdescId: number;
    rapdescOperatore: string;
    rapdescOre: number;
    rapdescNote: string;
    rapdescDescrizione: string;
    rapdescMisId: number;
    rapdescRapId: number;

    //proprietà locali
    rapdescSomma: number;
    rapdescCostoOrario: number;
}