import { HttpClient, HttpClientJsonpModule, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AnaAnagrafica } from '../objects/AnaAnagrafica';
import { AttAttrezzatura } from '../objects/AttAttrezzatura';
import { DipDipendente } from '../objects/DipDipendente';
import { MisMissioni } from '../objects/MisMissioni';
import { RapRapportiniFleet } from '../objects/RapportiniFleet';
import { RapportiniToSend } from '../objects/RapportiniToSend';
import { UsrUser } from '../objects/UsrUser';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getAllUsers(): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.get<UsrUser[]>(environment.endpointApi + 'users')
        .subscribe(users => {
          console.log('users getted: ', users);
          resolve(users);
        }, err => {
          console.log('Error getAllUsers: ', err);
          reject(false);
        });
    });
    return promise;
  }

  addOrEditUser(user: UsrUser): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.post<Boolean>(environment.endpointApi + 'users', user)
        .subscribe(ret => {
          console.log('users post: ', ret);
          resolve(ret);
        }, err => {
          console.log('Error addOrEditUser: ', err);
          reject(false);
        });
    });
    return promise;
  }

  deleteUser(user: UsrUser): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.delete<Boolean>(environment.endpointApi + 'users/' + user.usrId)
        .subscribe(ret => {
          console.log('users post: ', ret);
          resolve(ret);
        }, err => {
          console.log('Error deleteUser: ', err);
          reject(false);
        });
    });
    return promise;
  }

  getAllDipendenti(): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.get<DipDipendente[]>(environment.endpointApi + 'dipendenti')
        .subscribe(users => {
          console.log('users getted: ', users);
          resolve(users);
        }, err => {
          console.log('Error getAllDipendenti: ', err);
          reject(false);
        });
    });
    return promise;
  }

  addOrEditDipendenti(user: DipDipendente): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.post<Boolean>(environment.endpointApi + 'dipendenti', user)
        .subscribe(ret => {
          console.log('users post: ', ret);
          resolve(ret);
        }, err => {
          console.log('Error addOrEditDipendenti: ', err);
          reject(false);
        });
    });
    return promise;
  }

  deleteDipendente(user: DipDipendente): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.delete<Boolean>(environment.endpointApi + 'dipendenti/' + user.dipId)
        .subscribe(ret => {
          console.log('users post: ', ret);
          resolve(ret);
        }, err => {
          console.log('Error deleteDipendente: ', err);
          reject(false);
        });
    });
    return promise;
  }

  getAllAttrezzatura(): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.get<AttAttrezzatura[]>(environment.endpointApi + 'attrezzatura')
        .subscribe(users => {
          console.log('users getted: ', users);
          resolve(users);
        }, err => {
          console.log('Error getAllAttrezzatura: ', err);
          reject(false);
        });
    });
    return promise;
  }

  addOrEditAttrezzatura(user: AttAttrezzatura): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.post<Boolean>(environment.endpointApi + 'attrezzatura', user)
        .subscribe(ret => {
          console.log('users post: ', ret);
          resolve(ret);
        }, err => {
          console.log('Error addOrEditAttrezzatura: ', err);
          reject(false);
        });
    });
    return promise;
  }

  deleteAttrezzatura(user: AttAttrezzatura): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.delete<Boolean>(environment.endpointApi + 'attrezzatura/' + user.attId)
        .subscribe(ret => {
          console.log('users post: ', ret);
          resolve(ret);
        }, err => {
          console.log('Error deleteAttrezzatura: ', err);
          reject(false);
        });
    });
    return promise;
  }

  getAllAnagrafica(): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.get<AnaAnagrafica[]>(environment.endpointApi + 'anagrafica')
        .subscribe(users => {
          console.log('users getted: ', users);
          resolve(users);
        }, err => {
          console.log('Error getAllAttrezzatura: ', err);
          reject(false);
        });
    });
    return promise;
  }

  getAnagraficaById(idAnagrafica: number): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.get<AnaAnagrafica>(environment.endpointApi + 'anagrafica/' + idAnagrafica)
        .subscribe(users => {
          console.log('users getted: ', users);
          resolve(users);
        }, err => {
          console.log('Error getAllAttrezzatura: ', err);
          reject(false);
        });
    });
    return promise;
  }

  addOrEditAnagrafica(user: AnaAnagrafica): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.post<Boolean>(environment.endpointApi + 'anagrafica', user)
        .subscribe(ret => {
          console.log('users post: ', ret);
          resolve(ret);
        }, err => {
          console.log('Error addOrEditAttrezzatura: ', err);
          reject(false);
        });
    });
    return promise;
  }

  deleteAnagrafica(user: AnaAnagrafica): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.delete<Boolean>(environment.endpointApi + 'anagrafica/' + user.anaId)
        .subscribe(ret => {
          console.log('users post: ', ret);
          resolve(ret);
        }, err => {
          console.log('Error deleteAttrezzatura: ', err);
          reject(false);
        });
    });
    return promise;
  }

  getMissioniByDate(dateStart: string, dateEnd: string): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.get<AnaAnagrafica[]>(environment.endpointApi + 'missioni/' + dateStart + "/" + dateEnd)
        .subscribe(users => {
          console.log('users getted: ', users);
          resolve(users);
        }, err => {
          console.log('Error getAllAttrezzatura: ', err);
          reject(false);
        });
    });
    return promise;
  }

  getMissioniById(idMissione: number): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.get<AnaAnagrafica[]>(environment.endpointApi + 'missioni/' + idMissione)
        .subscribe(users => {
          console.log('users getted: ', users);
          resolve(users);
        }, err => {
          console.log('Error getAllAttrezzatura: ', err);
          reject(false);
        });
    });
    return promise;
  }

  addOrEditMissione(user: MisMissioni): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.post<Boolean>(environment.endpointApi + 'missioni', user)
        .subscribe(ret => {
          console.log('users post: ', ret);
          resolve(ret);
        }, err => {
          console.log('Error addOrEditAttrezzatura: ', err);
          reject(false);
        });
    });
    return promise;
  }

  deleteMissione(user: MisMissioni): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.delete<Boolean>(environment.endpointApi + 'missioni/' + user.misId)
        .subscribe(ret => {
          console.log('users post: ', ret);
          resolve(ret);
        }, err => {
          console.log('Error deleteMissione: ', err);
          reject(false);
        });
    });
    return promise;
  }

  getRapportiniFleetById(missionID: number): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.get<RapRapportiniFleet>(environment.endpointApi + 'rapportinifleet/' + missionID)
        .subscribe(users => {
          console.log('getRapportiFleetById getted: ', users);
          resolve(users);
        }, err => {
          console.log('Error getRapportiFleetById: ', err);
          reject(false);
        });
    });
    return promise;
  }

  addOrEditRapportiniFleet(appFlett: RapRapportiniFleet): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.post<Boolean>(environment.endpointApi + 'rapportinifleet', appFlett)
        .subscribe(ret => {
          console.log('addOrEditRapportiniFleet post: ', ret);
          resolve(ret);
        }, err => {
          console.log('Error addOrEditRapportiniFleet: ', err);
          reject(false);
        });
    });
    return promise;
  }

  sendReport(rapSend: RapportiniToSend): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      this.httpClient.post<Boolean>(environment.endpointApi + 'sendreport', rapSend)
        .subscribe(ret => {
          console.log('sendReport post: ', ret);
          resolve(ret);
        }, err => {
          console.log('Error sendReport: ', err);
          reject(false);
        });
    });
    return promise;
  }
}
