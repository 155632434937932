<div class="bodyContent">
    <div class="topper">
        <div class="finderTop">
            &nbsp;&nbsp;
            &nbsp;
            <mat-form-field appearance="fill">
                <mat-label>Inizio</mat-label>
                <input matInput style="font-weight: bolder; color: black;" [ngxMatDatetimePicker]="picker" placeholder="" [formControl]="dateControlStart" disabled>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker disabled="false" #picker color="primary"></ngx-mat-datetime-picker>
            </mat-form-field>
            &nbsp;&nbsp;
            <mat-form-field appearance="fill">
                <mat-label>Fine</mat-label>
                <input matInput style="font-weight: bolder; color: black;" [ngxMatDatetimePicker]="picker2" placeholder="" [formControl]="dateControlEnd" disabled>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker disabled="false" #picker2 color="primary"></ngx-mat-datetime-picker>
            </mat-form-field>
            &nbsp;&nbsp;
            <button mat-raised-button (click)="getMissioniBetweenDate()"><mat-icon>search</mat-icon> CERCA</button>
        </div>
        <button class="fab-add" mat-fab color="primary" (click)="openSetAgenda(null)"><mat-icon>more_time</mat-icon></button>
    </div>
    <div class="agende">
        <div *ngFor="let user of users">
            <div class="topCaposquadra">
                <mat-icon>group</mat-icon> <label>{{user.usrEmail}}</label>
            </div>
            <mat-card class="agendaBackground">
                <div *ngFor="let mis of getMissionFiltered(user)">
                    <div class="printApp">
                        <mat-card class="matCard" [ngStyle]="{'background-color': mis.misSendEmail ? '#B4FFB2' : 'lightgray' }">
                            <div class="titleMat">
                                <div class="customer">
                                    <mat-icon [style.color]="(mis.misRapId == null) ? 'red' : 'green'" title="stato">radio_button_checked</mat-icon> 
                                    <label>{{mis.misCustomerName}}</label>
                                    <mat-icon (click)="editMission(mis)" style="cursor: pointer;" title="modifica">edit</mat-icon>
                                </div>
                                <div>
                                    <br><label style="font-size: 1.35em;">{{mis.misDateStart | date: 'EEEE d MMMM yyyy'}} dalle {{mis.misDateStart | date: 'HH:mm'}} alle {{mis.misDateEnd | date: 'HH:mm'}}</label>
                                </div>
                                <div>
                                    <br><label style="font-size: 1.35em;">{{mis.misIndirizzo}}</label>
                                </div>
                            </div>
                            <div class="titleMat">
                                <br/><label>{{mis.misDipendentiName}}</label>
                            </div>
                            <div class="buttons">
                                <button mat-raised-button color="primary" (click)="editReport(mis)"><mat-icon>description</mat-icon> REPORT</button>
                                <button mat-raised-button color="primary" (click)="openConvalida(mis)" [disabled]="mis.misRapId == null"><mat-icon>check</mat-icon> CONVALIDA</button>
                            </div>
                            <div *ngIf="mis.misSendEmailDate != null" style="text-align:right; color: #555;">
                                <small><b>REPORT INVIATO ({{mis.misSendEmail}}) IL {{mis.misSendEmailDate | date: 'dd-MM-yyyy'}} ALLE {{mis.misSendEmailDate | date: 'HH:mm'}}</b></small>
                            </div>
                            <div *ngIf="mis.misNoteCantiere"><br>
                                <button mat-raised-button (click)="editNoteCantiere(mis)">Note dal cantiere</button> {{mis.misNoteCantiere}}
                            </div>
                        </mat-card>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>