import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgendaComponent } from './agenda/agenda.component';
import { AnagraficaComponent } from './anagrafica/anagrafica.component';
import { AttrezzaturaComponent } from './attrezzatura/attrezzatura.component';
import { DipendentiComponent } from './dipendenti/dipendenti.component';
import { LoginComponent } from './login/login.component';
import { UtentiComponent } from './utenti/utenti.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'anagrafica', component: AnagraficaComponent },
  { path: 'attrezzatura', component: AttrezzaturaComponent },
  { path: 'dipendenti', component: DipendentiComponent },
  { path: 'utenti', component: UtentiComponent },
  { path: 'agenda', component: AgendaComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
