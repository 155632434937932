<div class="bodyContent">
    <div class="topper">
        <h1>Anagrafica</h1>
        <button class="fab-add" mat-fab color="primary" (click)="addElement()"><mat-icon>add</mat-icon></button>
    </div>
    <mat-form-field>
        <mat-label>Cerca...</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
    </mat-form-field>      
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
      
        <!-- Position Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Cliente </th>
            <td mat-cell *matCellDef="let element"> {{element.anaRagioneSociale}}</td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef> Nome </th>
            <td mat-cell *matCellDef="let element">
                <label *ngIf="element.anaName">{{element.anaName}} </label>
                <label *ngIf="!element.anaName">-</label>
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="cognome">
            <th mat-header-cell *matHeaderCellDef> Cognome </th>
            <td mat-cell *matCellDef="let element"> 
                <label *ngIf="element.anaName">{{element.anaSurname}} </label> 
                <label *ngIf="!element.anaName">-</label> 
            </td>
          </ng-container>
            
          <!-- Weight Column -->
          <ng-container matColumnDef="enable">
              <th mat-header-cell *matHeaderCellDef> Piva </th>
              <td mat-cell *matCellDef="let element">
                  <label *ngIf="element.anaPiva">{{element.anaPiva}}</label>
                  <label *ngIf="!element.anaPiva">-</label>
              </td>
          </ng-container>   
            
          <!-- Weight Column -->
          <ng-container matColumnDef="codfiscale">
              <th mat-header-cell *matHeaderCellDef> Cod. Fiscale </th>
              <td mat-cell *matCellDef="let element">
                  <label *ngIf="element.anaCodiceFiscale">{{element.anaCodiceFiscale}}</label>
                  <label *ngIf="!element.anaCodiceFiscale">-</label>
              </td>
          </ng-container>   

        <!-- Weight Column -->
        <ng-container matColumnDef="luogo">
        <th mat-header-cell *matHeaderCellDef> Locazione </th>
        <td mat-cell *matCellDef="let element">{{element.anaComune}} {{element.anaProvincia}}</td>
        </ng-container> 

        <!-- Weight Column -->
        <ng-container matColumnDef="emailaddress">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element">{{element.anaEmail}}</td>
        </ng-container>   
        
      
        <!-- Weight Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Azioni </th>
          <td mat-cell *matCellDef="let element">
            <button color="primary" mat-raised-button (click)="editElement(element)"><mat-icon>edit</mat-icon></button>&nbsp;
            <button color="warn" mat-raised-button (click)="deleteElement(element)"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>      
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Carico..</td>
        </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
</div>