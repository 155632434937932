export const environment = {
  production: false,
  frontendUrl: 'https://gardezoo.applika.biz/',
  backendUrl: 'https://gardenzoobe.applika.biz',
  endpointApi: "https://gardenzoobe.applika.biz/api/",
  tipoAttrezzatura:{
    litri: 0,
    unita: 1,
    ore: 2
  }
};

