<mat-progress-bar class="progressbar" *ngIf="showLoading" mode="indeterminate"></mat-progress-bar>
<div class="bodyPage" *ngIf="!showLogin">
    <div class="first-column">
        <img src="../assets/images/logo.jpg">
        <ul>
            <li routerLink="agenda" routerLinkActive="router-link-active"><mat-icon>home</mat-icon>Agenda</li>
            <li routerLink="anagrafica" routerLinkActive="router-link-active"><mat-icon>group</mat-icon>Anagrafica</li>
            <li routerLink="attrezzatura" routerLinkActive="router-link-active"><mat-icon>precision_manufacturing</mat-icon>Attrezzatura</li>
            <li routerLink="dipendenti" routerLinkActive="router-link-active"><mat-icon>engineering</mat-icon>Dipendenti</li>
            <li routerLink="utenti" routerLinkActive="router-link-active"><mat-icon>manage_accounts</mat-icon>Utenti</li>
            <li (click)="logout()"><mat-icon>logout</mat-icon>Esci</li>
        </ul>
    </div>
    <div class="second-column">
        <mat-spinner *ngIf="showLoading" diameter="60"></mat-spinner>
        <router-outlet></router-outlet>        
    </div>
</div>

<router-outlet *ngIf="showLogin"></router-outlet>        