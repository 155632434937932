import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnaAnagrafica } from 'src/app/objects/AnaAnagrafica';
import { AttAttrezzatura } from 'src/app/objects/AttAttrezzatura';
import { DipDipendente } from 'src/app/objects/DipDipendente';
import { MisMissioni } from 'src/app/objects/MisMissioni';
import { UsrUser } from 'src/app/objects/UsrUser';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-edit-agenda',
  templateUrl: './edit-agenda.component.html',
  styleUrls: ['./edit-agenda.component.scss']
})
export class EditAgendaComponent implements OnInit {

  users: UsrUser[]
  attrezzature: AttAttrezzatura[]
  dipendenti: DipDipendente[]
  anagrafiche: AnaAnagrafica[]
  anagraficaChoiche: AnaAnagrafica
  userChoiche: UsrUser
  toSave: MisMissioni = new MisMissioni();
  note: string = "";

  dateControlStart = new FormControl(new Date());
  dateControlEnd = new FormControl(new Date());
  @ViewChild('picker1') picker1: any;
  @ViewChild('picker2') picker2: any;

  // minDate = new Date(new Date().setDate(new Date().getDate() - 1));
  minDate = new Date(new Date().setDate(new Date().getDate()));
  toppings = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<EditAgendaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MisMissioni,
    private loadingService: LoadingService,
    private apiService: ApiService,
    private datePipe: DatePipe,
    private snackBarService: SnackbarService) {

      this.loadingService.showLoading();
      this.apiService.getAllUsers()
      .then(users => {
        this.users = users as UsrUser[];
        this.users = this.users.filter(x => x.usrType == 1);

        this.apiService.getAllAnagrafica()
        .then(anagrafiche => {
          this.anagrafiche = anagrafiche as AnaAnagrafica[];

          this.apiService.getAllDipendenti()
          .then(dipendenti => {
            this.dipendenti = dipendenti as DipDipendente[];
            this.loadingService.hideLoading();


            if(this.data !== null){
              this.toSave.misId = this.data.misId;
              this.toSave.misDateTime = this.data.misDateTime;
              this.toSave.misDipendentiName = this.data.misDipendentiName;
              let idDipendenti = this.data.misDipendenti.split(',')
              //setto i dipendenti nel menu a tendina
              let dipForToppings: DipDipendente[] = []
              idDipendenti.forEach(x=> {
                let dip: DipDipendente = this.dipendenti.filter(d => d.dipId === +x)[0];
                if(dip != undefined){
                  dipForToppings.push(dip);
                }
              })
              this.toppings.setValue(dipForToppings);
              // setto il capo squadra
              this.toSave.misCapoSquadra = this.data.misCapoSquadra;
              // setto il cliente
              this.toSave.misCustomerName = this.data.misCustomerName;
              this.toSave.misCustomer = this.data.misCustomer;

              this.anagraficaChoiche = this.anagrafiche.filter(x => x.anaId == this.data.misCustomer)[0]
              this.userChoiche = this.users.filter(x => x.usrId == this.data.misCapoSquadra)[0]

              this.toSave.misDateStart = this.data.misDateStart
              this.toSave.misDateEnd = this.data.misDateEnd;

              this.note = this.data.misNote;

              this.dateControlStart.setValue(new Date(this.data.misDateStart))
              this.dateControlEnd.setValue(new Date(this.data.misDateEnd))
            }
          })
        })
      })
      // this.apiService.getAllAttrezzatura()
      // .then(attrezzature => {
      //   this.attrezzature = attrezzature as AttAttrezzatura[];
      // })
      
      

    }

  ngOnInit(): void {
  }

  saveData(){
    console.log(this.dateControlStart.value)
    console.log(this.dateControlEnd.value)
    console.log(this.userChoiche)
    console.log(this.anagraficaChoiche)
    console.log(this.toppings.value)

    this.loadingService.showLoading();

    this.toSave.misDateTime = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss');
    this.toSave.misDipendentiName = "";
    this.toSave.misDipendenti = "";
    this.toppings.value.forEach((element: DipDipendente) => {
      this.toSave.misDipendentiName += element.dipCognome + " " + element.dipNome + ","
      this.toSave.misDipendenti += element.dipId + ","
    });
    this.toSave.misDateStart = this.datePipe.transform(this.dateControlStart.value, 'yyyy-MM-ddTHH:mm:ss');
    this.toSave.misDateEnd = this.datePipe.transform(this.dateControlEnd.value, 'yyyy-MM-ddTHH:mm:ss');
    this.toSave.misCustomer = this.anagraficaChoiche.anaId
    this.toSave.misCustomerName = (this.anagraficaChoiche.anaRagioneSociale !== null) ? this.anagraficaChoiche.anaRagioneSociale : ' ' + ' ' + this.anagraficaChoiche.anaName + ' ' + this.anagraficaChoiche.anaSurname;
    this.toSave.misCapoSquadra = this.userChoiche.usrId
    this.toSave.misIndirizzo = this.anagraficaChoiche.anaAddress + "," + this.anagraficaChoiche.anaComune + "," + this.anagraficaChoiche.anaProvincia + "," + this.anagraficaChoiche.anaCap
    // debugger;
    if(this.toSave.misNoteCantiere != undefined){
      this.toSave.misNoteCantiere = this.data.misNoteCantiere;
    }
  
    debugger;
    if(this.data !== null){
      this.toSave.misRapId = this.data.misRapId;
    }
    this.toSave.misNote = this.note;
   
    console.log(this.toSave)
    this.apiService.addOrEditMissione(this.toSave)
    .then(x => {
      if(x){
        this.snackBarService.openSnackBar("Operazione eseguita con successo")
        this.dialogRef.close();
      } else {
        this.snackBarService.openSnackBar("Ops qualcosa è andato storto")
      }
    }, err => {
      console.log('Errore post missione: ', err)
    })
  }

  close(){
    this.dialogRef.close();
  }

  deleteMission() {
    if(confirm('Sei sicuro? Azione irreversibile')){
      this.apiService.deleteMissione(this.data)
      .then(x => {
        if(x){
          this.snackBarService.openSnackBar("Eliminato con successo.");
          this.close();
        } else {
          this.snackBarService.openSnackBar("Ops qualcosa è andato storto");
        }
      })
    }
  }

}
