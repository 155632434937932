import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsrUser } from '../objects/UsrUser';
import { ApiService } from '../services/api.service';
import { LoadingService } from '../services/loading.service';
import { SessionService } from '../services/session.service';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email
  password

  constructor(
    private snackBarService: SnackbarService,
    private sessionService: SessionService,
    private apiService: ApiService,
    private loadingService: LoadingService,
    private router: Router
  ) {

  }

  ngOnInit(): void { }

  doLogin() {
    this.loadingService.showLoading();
    this.apiService.getAllUsers()
    .then((users: UsrUser[]) => {
      console.log(users);
      users = users.filter(x => x.usrType == 0);
      if(users[0].usrEmail == this.email && users[0].usrPassword == this.password){
        this.snackBarService.openSnackBar("Login avvenuto con successo");
        let userLogged: UsrUser = new UsrUser();
        userLogged.usrEmail = users[0].usrEmail;
        userLogged.usrPassword = users[0].usrPassword;
        this.sessionService.setGenericSession(userLogged, 'login')
        window.location.reload();
      } else {
        this.snackBarService.openSnackBar("Credenziali errate");
      }
      this.loadingService.hideLoading();
    }, err => {
      console.log('Login error: ', err)
      this.loadingService.hideLoading();
    })
  }

}
