import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttAttrezzatura } from 'src/app/objects/AttAttrezzatura';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-edit-attrezzatura',
  templateUrl: './edit-attrezzatura.component.html',
  styleUrls: ['./edit-attrezzatura.component.scss']
})
export class EditAttrezzaturaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditAttrezzaturaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AttAttrezzatura,
    private loadingService: LoadingService,
    private apiService: ApiService,
    private snackBarService: SnackbarService
    ) {
      console.log(data)
     }

  ngOnInit(): void {
  }

  saveData(){
    this.loadingService.showLoading();
    console.log(this.data)

    this.data.attTipoAttrezzatura = +this.data.attTipoAttrezzatura
    
    this.apiService.addOrEditAttrezzatura(this.data)
    .then(ret => {
      if(ret){
        this.snackBarService.openSnackBar("Operazione eseguita con successo.")
        this.dialogRef.close();
      } else {
        this.snackBarService.openSnackBar("Ops, qualcosa è andato storto, si prega di riprovare.")
      }
    }, err => {
      console.log(err);
    })
  }

  close(){
    this.dialogRef.close();
  }

}
