import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsrUser } from 'src/app/objects/UsrUser';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-edit-utente',
  templateUrl: './edit-utente.component.html',
  styleUrls: ['./edit-utente.component.scss']
})
export class EditUtenteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditUtenteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsrUser,
    private loadingService: LoadingService,
    private apiService: ApiService,
    private snackBarService: SnackbarService
    ) {
      console.log(data)
     }

  ngOnInit(): void {
  }

  saveData(){
    this.loadingService.showLoading();
    console.log(this.data)
    
    if(this.data.usrType == null){
      this.data.usrType = 1;
    }

    this.apiService.addOrEditUser(this.data)
    .then(ret => {
      if(ret){
        this.snackBarService.openSnackBar("Operazione eseguita con successo.")
        this.dialogRef.close();
      } else {
        this.snackBarService.openSnackBar("Ops, qualcosa è andato storto, si prega di riprovare.")
      }
    }, err => {
      console.log(err);
    })
  }

  close(){
    this.dialogRef.close();
  }
}
