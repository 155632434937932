import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { rawListeners } from 'process';
import { zip } from 'rxjs';
import { AnaAnagrafica } from 'src/app/objects/AnaAnagrafica';
import { AttAttrezzatura } from 'src/app/objects/AttAttrezzatura';
import { DipDipendente } from 'src/app/objects/DipDipendente';
import { MisMissioni } from 'src/app/objects/MisMissioni';
import { RapAttRapportiniAttrezzature } from 'src/app/objects/RapAttRapportiniAttrezzature';
import { RapdescRapportiniDescrizioni } from 'src/app/objects/RapdescRapportiniDescrizioni';
import { RapRapportiniFleet } from 'src/app/objects/RapportiniFleet';
import { RapRapportini } from 'src/app/objects/RapRapportini';
import { RapumRapportiniUm } from 'src/app/objects/RapumRapportiniUm';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-edit-report',
  templateUrl: './edit-report.component.html',
  styleUrls: ['./edit-report.component.scss']
})
export class EditReportComponent implements OnInit {

  appFleet: RapRapportiniFleet = new RapRapportiniFleet();
  dipendenti: DipDipendente[] = [];
  attrezzature: AttAttrezzatura[] = [];

  sommaCalcoloCosti: number = 0;
  sommaCalcoloCostiAttrezzaturaNonTrovata = ''
  showSomma: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MisMissioni,
    private loadingService: LoadingService,
    private apiService: ApiService,
    private snackBarService: SnackbarService,
    private datePipe: DatePipe
    ) {
      this.loadingService.showLoading();
      this.appFleet.rapAttRapportiniAttrezzature = [];
      this.appFleet.rapdescRapportiniDescrizioni = [];
      this.appFleet.rapumRapportiniUm = [];

      if(this.data.misRapId != null){
        this.apiService.getRapportiniFleetById(this.data.misId)
        .then((rapFleet: RapRapportiniFleet) => {
          console.log('rapFleet: ', rapFleet);
          this.appFleet = rapFleet;
        });
      }

      console.log(this.data)
      this.apiService.getAllDipendenti()
      .then(dipendenti => {
        this.dipendenti = dipendenti as DipDipendente[];
        this.loadingService.hideLoading();
      })

      this.apiService.getAllAttrezzatura()
      .then(attrezzature => {
        this.attrezzature = attrezzature as AttAttrezzatura[];
        this.loadingService.hideLoading();
      })
  }

  onDipChange(ev: any) {
    let dip: DipDipendente = ev.value;
    let check = this.appFleet.rapdescRapportiniDescrizioni.filter(x => x.rapdescOperatore == dip.dipCognome + " " + dip.dipNome)[0]
    
    if(check === undefined){
      let dipTmp: RapdescRapportiniDescrizioni = new RapdescRapportiniDescrizioni();
      dipTmp.rapdescOperatore = dip.dipCognome + " " + dip.dipNome;
      dipTmp.rapdescMisId = this.data.misId;
      this.appFleet.rapdescRapportiniDescrizioni.push(dipTmp);
    } else {
      this.snackBarService.openSnackBar("Operatore già inserito")
    }
  }

  removeDip(toRemove: RapdescRapportiniDescrizioni){
    const index = this.appFleet.rapdescRapportiniDescrizioni.indexOf(toRemove, 0);
    if (index > -1) {
      this.appFleet.rapdescRapportiniDescrizioni.splice(index, 1);
    }
  }

  onAttChange(ev: any) {
    let att: AttAttrezzatura = ev.value;    
    let dipTmp: RapAttRapportiniAttrezzature = new RapAttRapportiniAttrezzature();
    dipTmp.rapattAttrezzatura = att.attAttrezzatura
    dipTmp.rapattMisId = this.data.misId
    // litri: 0,
    // unita: 1,
    // ore: 2,
    // km: 3
    dipTmp.rapattRapType = att.attTipoAttrezzatura
    this.appFleet.rapAttRapportiniAttrezzature.push(dipTmp);
  }

  removeAtt(toRemove: RapAttRapportiniAttrezzature){
    const index = this.appFleet.rapAttRapportiniAttrezzature.indexOf(toRemove, 0);
    if (index > -1) {
      this.appFleet.rapAttRapportiniAttrezzature.splice(index, 1);
    }
  }

  addArtUm() { 
    let dipTmp: RapumRapportiniUm = new RapumRapportiniUm();
    dipTmp.rapumMisId = this.data.misId
    this.appFleet.rapumRapportiniUm.push(dipTmp);
  }

  removeArt(toRemove: RapumRapportiniUm){
    const index = this.appFleet.rapumRapportiniUm.indexOf(toRemove, 0);
    if (index > -1) {
      this.appFleet.rapumRapportiniUm.splice(index, 1);
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  saveData() {
    // save and close
    this.loadingService.showLoading();
    this.apiService.getAnagraficaById(this.data.misCustomer)
    .then((ana: AnaAnagrafica) => {

      if(this.data.misRapId == null){
        this.appFleet.rapRapportini = new RapRapportini();
      }
      this.appFleet.rapRapportini.rapMisId = this.data.misId
      this.appFleet.rapRapportini.rapCaposquadra = this.data.misCapoSquadra
      this.appFleet.rapRapportini.rapCustomerId = ana.anaId
      this.appFleet.rapRapportini.rapCodiceFiscale = ana.anaCodiceFiscale
      this.appFleet.rapRapportini.rapCustomerString = ana.anaRagioneSociale
      this.appFleet.rapRapportini.rapPiva = ana.anaPiva
      this.appFleet.rapRapportini.rapIndirizzo = ana.anaAddress
      this.appFleet.rapRapportini.rapCustomerTelefono = ana.anaTelefono
      this.appFleet.rapRapportini.rapDataIntervento = this.datePipe.transform(this.data.misDateStart, 'yyyy-MM-ddTHH:mm:ss');
      // this.appFleet.rapRapportini.rapInterventoNum TODO mettere il numero progressivo - fatto a backend
      console.log('before save: ', this.appFleet)

      // debugger;
      this.apiService.addOrEditRapportiniFleet(this.appFleet)
      .then(x => {
        this.loadingService.hideLoading();
        if(x){
          this.snackBarService.openSnackBar('Operazione eseguita con successo');
          this.close();
        } else {
          this.snackBarService.openSnackBar("ops, qualcosa è andato storto si prega di riprovare");
        }
      })

      console.log(this.appFleet)
    })
  }

  calcolaCosti() {
    this.sommaCalcoloCosti = 0;
    this.sommaCalcoloCostiAttrezzaturaNonTrovata = '';

    this.appFleet.rapdescRapportiniDescrizioni.forEach(x => {
      try {
        x.rapdescSomma = 0;
        x.rapdescCostoOrario = this.dipendenti.filter(y => (y.dipCognome.replace(/\s/g, "") + y.dipNome.replace(/\s/g, "")).startsWith(x.rapdescOperatore.replace(/\s/g, "")))[0].dipCostoOrario;
        x.rapdescSomma = x.rapdescOre * x.rapdescCostoOrario;
        this.sommaCalcoloCosti += x.rapdescSomma;
      } catch (error) {
        console.log('ERRORE CALCOLO COSTI NON TROVATO: ', x.rapdescDescrizione)
        this.sommaCalcoloCostiAttrezzaturaNonTrovata += ' ' + x.rapdescDescrizione
      }
    })

    this.appFleet.rapAttRapportiniAttrezzature.forEach(x => {
      debugger;
      try{
        x.rapattSomma = 0;
        x.rapattCostoOrario = this.attrezzature.filter(y => y.attAttrezzatura.replace(/\s/g, "").startsWith(x.rapattAttrezzatura.replace(/\s/g, "")))[0].attCostoOrario;
        x.rapattSomma = x.rapattRapUnita * x.rapattCostoOrario;
        this.sommaCalcoloCosti += x.rapattSomma;
      } catch (error) {
        console.log('ERRORE CALCOLO COSTI NON TROVATO: ', x.rapattAttrezzatura)      
        this.sommaCalcoloCostiAttrezzaturaNonTrovata += ' -->' + x.rapattAttrezzatura  
      }
    })
    this.showSomma = true;
  }

  hideCosti() {
    this.showSomma = false;
  }
}
