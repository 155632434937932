import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsrUser } from './objects/UsrUser';
import { ApiService } from './services/api.service';
import { LoadingService } from './services/loading.service';
import { SessionService } from './services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  showLoading = false
  showFiller = false;
  showLogin = true;
  
  constructor(
    private loadingService: LoadingService,
    public dialog: MatDialog,
    private sessionService: SessionService,
    private router: Router
  ) {
    this.loadingService.loading.subscribe(loading => {
      this.showLoading = loading;
    })

    let login: UsrUser = JSON.parse(this.sessionService.getGenericSession('login'));
    if(login == null){
      this.showLogin = true;
    } else {
      this.showLogin = false;
      this.router.navigate(['agenda']);
    }
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {}

  logout() {
    localStorage.clear();
    window.location.href = "login"
  }
}





