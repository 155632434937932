<!-- <b style="color: red;">NB: L'anteprima NON rappresenta la stampa effettiva, è solo a scopo visivo</b> -->
<div id="pdfTable" #pdfTable style="font-family: Arial, Helvetica, sans-serif;">
    <div class="pdfStructure">
        <div class="topPdf">
            <div class="logoTop">
                <img src="../../../assets/images/logo.jpg" height="28"><br/>
                <div>
                    Viale Gramsci, n.363 - 41037 Mirandola (MO)<br/>
                    Tel.: 0535 21556 - Fax: 0535 418119 - www.gardenzoo.net - email: info@gardenzoo.net
                    Partita Iva 01462360361 - C.F. GBRNDR61P06F257X
                </div>
            </div> 
            <div class="dataTop">
                <div class="rowDataTop">
                    {{appFleet.rapRapportini?.rapCustomerString}} - {{appFleet.rapRapportini?.rapDataIntervento | date: 'dd-MM-yyyy'}} - Intervento n° {{appFleet.rapRapportini?.rapInterventoNum}} 
                </div>
                <!-- <div class="rowDataTop">
                    
                </div> -->
                <!-- <div class="rowDataTop">
                    &nbsp;Cliente {{appFleet.rapRapportini?.rapCustomerString}}
                </div> -->
                <div class="rowDataTop">
                    &nbsp;Indirizzo: {{appFleet.rapRapportini?.rapIndirizzo}}
                </div>
                <div class="rowDataTop" style="border-bottom: none; padding-bottom: 8px;">
                    &nbsp;C.F.: {{appFleet.rapRapportini.rapPiva}} Tel.: {{appFleet.rapRapportini.rapTelefono}}
                </div>
            </div> 
        </div>
        <div class="pdfDesc">
            <div class="tableCustom">
                <div>
                    <label style="width: 40%;">Descrizione intervento</label>
                    <label style="width: 15%;">Operatori</label>
                    <label style="width: 5%;">Ore</label>
                    <label style="width: 40%;">Note</label>
                </div>
                <div *ngFor="let item of appFleet?.rapdescRapportiniDescrizioni">
                    <label style="width: 40%;">{{item.rapdescDescrizione}}</label>
                    <label style="width: 15%;">{{item.rapdescOperatore}}</label>
                    <label style="width: 5%;">{{item.rapdescOre}}</label>
                    <label style="width: 40%;">{{item.rapdescNote}}</label>
                </div>
            </div>
        </div>
        <div class="pdfAtt">
            <!-- litri: 0,
            unita: 1,
            ore: 2,
            km: 3 -->
            <div class="tableCustom">
                <div>
                    <label style="width: 72%;">Attrezzatura</label>
                    <label style="width: 7%;">Litri</label>
                    <label style="width: 7%;">Unità</label>
                    <label style="width: 7%;">Ore</label>
                    <label style="width: 7%;">KM/GG</label>
                </div>
                <div *ngFor="let item of appFleet?.rapAttRapportiniAttrezzature">
                    <label style="width: 72%;">{{item.rapattAttrezzatura}}</label>
                    <label style="width: 7%;">{{(item.rapattRapType === 0) ? item.rapattRapUnita : ''}}</label>
                    <label style="width: 7%;">{{(item.rapattRapType === 1) ? item.rapattRapUnita : ''}}</label>
                    <label style="width: 7%;">{{(item.rapattRapType === 2) ? item.rapattRapUnita : ''}}</label>
                    <label style="width: 7%;">{{(item.rapattRapType === 3) ? item.rapattRapUnita : ''}}</label>
                </div>
            </div>
        </div>
        <div class="pdfUM">
            <div class="tableCustom">
                <div>
                    <label style="width: 7%;">UM</label>
                    <label style="width: 7%;">Q.tà</label>
                    <label style="width: 11%;">Cod. Articolo</label>
                    <label style="width: 75%;">Descrizione</label>
                </div>
                <div *ngFor="let item of appFleet?.rapumRapportiniUm">
                    <label style="width: 7%;">{{item.rapumUm}}</label>
                    <label style="width: 7%;">{{item.rapumQta}}</label>
                    <label style="width: 11%;">{{item.rapumCodArticolo}}</label>
                    <label style="width: 75%;">{{item.rapumDescrizione}}</label>
                </div>
            </div>
        </div>
        <!-- <div class="pdfNote">
            &nbsp;Note: {{mission.misNote}}
        </div> -->
        <div class="pdfDirittoChiamata">
            &nbsp;Diritto di chiamata n. 1
        </div>
        <div class="pdfFooter">
            <div>
                <b>&nbsp;CONDIZIONI</b>
                <label>&nbsp;&nbsp;1) Intervento Minimo per 1 per operatore.</label>
                <label>&nbsp;&nbsp;2) Le operazioni vanno pagate al ricevimento della fattura salvo accordi particolari. In caso di ritardato pagamento l'importo &nbsp;&nbsp;sarà maggiorato del tasso d'interesse bancario in vigore.</label>
                <label>&nbsp;&nbsp;3) Agli effetti dell'Art. 1342 del C.C. l'acquirente sottoscrivendo le presenti condizioni, conferma di aver preso cognizione &nbsp;&nbsp;dello stesso e di accettarlo integralmente.</label>
            </div>
            <div class="signatureSpace">
                <div style="margin-top: 8px;">
                    <b>FIRMA PER ACCETTAZZIONE</b>
                </div>
                <div>
                    <img [src]="appFleet.rapRapportini.rapFirma" height="30" style="margin-left: 0px;">
                </div>
                <!-- <div *ngIf="!appFleet.rapRapportini.rapFirma">
                    ____________________________________
                </div> -->
            </div>
        </div>
    </div>
</div>
<div class="buttons">
    <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput style="font-weight: bolder; color: black;" placeholder="" [(ngModel)]="emailToSend">
    </mat-form-field>
    <!-- <div> <button mat-raised-button color="primary" (click)="openPDF()" [disabled]="!enablePrint"><mat-icon>print</mat-icon></button></div> -->
    <div> <button mat-raised-button color="primary" (click)="openPDF()"><mat-icon>print</mat-icon></button></div>
    <div> <button mat-raised-button color="primary" (click)="send()"><mat-icon>send</mat-icon> Convalida ed invia al cliente</button></div>
    <div> <button mat-raised-button (click)="closeDialog()">Annulla</button></div>
</div>
