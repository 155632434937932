export class RapRapportini {
    rapId: number;
    rapCustomerId: number;
    rapCustomerString: string;
    rapStart: Date;
    rapEnd: Date;
    rapMisId: number;
    rapFirma?: any;
    rapInterventoNum: number;
    rapDataIntervento: Date | string;
    rapCustomerTelefono: string;
    rapCodiceFiscale: string;
    rapTelefono: string;
    rapPiva: string;
    rapCaposquadra: number;
    rapCaposquadraString: string;
    rapIndirizzo: string
}