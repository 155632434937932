<div class="formEdit">
    <mat-form-field appearance="fill">
        <mat-label>Ragione Sociale</mat-label>
        <input matInput [(ngModel)]="data.anaRagioneSociale" placeholder="">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Nome</mat-label>
        <input matInput [(ngModel)]="data.anaName" placeholder="">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Cognome</mat-label>
        <input matInput [(ngModel)]="data.anaSurname" placeholder="">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="data.anaEmail" placeholder="">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Telefono</mat-label>
        <input matInput [(ngModel)]="data.anaTelefono" placeholder="">
    </mat-form-field>    
    <mat-form-field appearance="fill">
        <mat-label>Indirizzo</mat-label>
        <input matInput [(ngModel)]="data.anaAddress" placeholder="">
    </mat-form-field>    
    <mat-form-field appearance="fill">
        <mat-label>Provincia</mat-label>
        <input matInput [(ngModel)]="data.anaProvincia" placeholder="">
    </mat-form-field>    
    <mat-form-field appearance="fill">
        <mat-label>Comune</mat-label>
        <input matInput [(ngModel)]="data.anaComune" placeholder="">
    </mat-form-field>  
    <mat-form-field appearance="fill">
        <mat-label>CAP</mat-label>
        <input matInput [(ngModel)]="data.anaCap" placeholder="">
    </mat-form-field>    
    <mat-form-field appearance="fill">
        <mat-label>Piva</mat-label>
        <input matInput [(ngModel)]="data.anaPiva" placeholder="">
    </mat-form-field>    
    <mat-form-field appearance="fill">
        <mat-label>Cod. fiscale</mat-label>
        <input matInput [(ngModel)]="data.anaCodiceFiscale" placeholder="">
    </mat-form-field>    
    <button mat-raised-button color="primary" (click)="saveData()">SALVA</button>
    <br/>
    <button mat-raised-button (click)="close()">Annulla e chiudi</button>
</div>
