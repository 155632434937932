export class RapAttRapportiniAttrezzature {
    rapattId: number;
    rapattAttrezzatura: string;
    rapattMisId: number;
    rapattRapId: number;
    rapattRapType: number;
    rapattRapUnita: number;

    // proprietà locali
    rapattSomma: number;
    rapattCostoOrario: number;
}
