import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnaAnagrafica } from 'src/app/objects/AnaAnagrafica';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-edit-anagrafica',
  templateUrl: './edit-anagrafica.component.html',
  styleUrls: ['./edit-anagrafica.component.scss']
})
export class EditAnagraficaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditAnagraficaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AnaAnagrafica,
    private loadingService: LoadingService,
    private apiService: ApiService,
    private snackBarService: SnackbarService
    ) {
      console.log(data)
     }

  ngOnInit(): void {
  }

  saveData(){
    console.log(this.data)
    if(this.data.anaRagioneSociale == undefined || this.data.anaRagioneSociale == "" || this.data.anaName == undefined || this.data.anaName == "" || this.data.anaSurname == undefined || this.data.anaSurname == ""){
      this.snackBarService.openSnackBar("Inserisci ragione sociale, nome e cognome. Se è un cliente privato riporta il nome e cognome anche nel campo RAGIONE SOCIALE")
    } else {
      this.loadingService.showLoading();
      this.apiService.addOrEditAnagrafica(this.data)
      .then(ret => {
        if(ret){
          this.snackBarService.openSnackBar("Operazione eseguita con successo.")
          this.dialogRef.close();
        } else {
          this.snackBarService.openSnackBar("Ops, qualcosa è andato storto, si prega di riprovare.")
        }
      }, err => {
        console.log(err);
      })
    }
  }

  close(){
    this.dialogRef.close();
  }
}
