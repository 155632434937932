import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MisMissioni } from 'src/app/objects/MisMissioni';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-edit-notecantiere',
  templateUrl: './edit-notecantiere.component.html',
  styleUrls: ['./edit-notecantiere.component.scss']
})
export class EditNotecantiereComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditNotecantiereComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MisMissioni,
    private loadingService: LoadingService,
    private apiService: ApiService,
    private snackBarService: SnackbarService,
    private datePipe: DatePipe
    ) {
      // chiamare la missione
      console.log(this.data);
     }

  ngOnInit() {}

  saveMission() {
    console.log(this.data);
    this.apiService.addOrEditMissione(this.data)
    .then(x => {
      if(x){
        this.snackBarService.openSnackBar('Operazione eseguita con successo.');
        this.close();
      } else {
        this.snackBarService.openSnackBar('OPS, qualcosa non va, riprova.');
      }
    });
  }

  close() {
    this.dialogRef.close();
  }
}
