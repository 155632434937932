import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EditAnagraficaComponent } from '../modals/edit-anagrafica/edit-anagrafica.component';
import { EditAttrezzaturaComponent } from '../modals/edit-attrezzatura/edit-attrezzatura.component';
import { AnaAnagrafica } from '../objects/AnaAnagrafica';
import { AttAttrezzatura } from '../objects/AttAttrezzatura';
import { ApiService } from '../services/api.service';
import { LoadingService } from '../services/loading.service';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-anagrafica',
  templateUrl: './anagrafica.component.html',
  styleUrls: ['./anagrafica.component.scss']
})
export class AnagraficaComponent implements OnInit {

 
  users: AnaAnagrafica[] = [];

  displayedColumns: string[] = ['email', 'nome', 'cognome', 'enable', 'codfiscale', 'luogo', 'emailaddress', 'actions'];
  dataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private loadingService: LoadingService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private snackBarService: SnackbarService
  ) {
    this.loadingService.showLoading();
    this.getAllUsers();
   }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getAllUsers() {
    this.apiService.getAllAnagrafica()
    .then((users: AnaAnagrafica[]) => {
      console.log(users)
      this.loadingService.hideLoading();
      this.dataSource = new MatTableDataSource(users);
      this.dataSource.paginator = this.paginator;      
    })
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.users);
    this.dataSource.paginator = this.paginator;
  }

  addElement() {
    let user: AnaAnagrafica = new AnaAnagrafica();
    const dialogRef = this.dialog.open(EditAnagraficaComponent, {
      width: '80%',
      // data: {name: this.name, animal: this.animal}
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
    });
  }

  editElement(user: AnaAnagrafica){
    const dialogRef = this.dialog.open(EditAnagraficaComponent, {
      width: '80%',
      // data: {name: this.name, animal: this.animal}
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
    });
  }

  deleteElement(user: AnaAnagrafica){
    if(confirm('Sei sicuro? Azione irreversibile, le missioni di questo utente saranno resettate')){
      this.loadingService.showLoading();
      this.apiService.deleteAnagrafica(user)
      .then(x => {
        if(x){
          this.getAllUsers();
          this.snackBarService.openSnackBar("Operazione eseguita con successo")
        } else {
          this.snackBarService.openSnackBar("Ops, qualcosa è andato storto si prega di riprovare")
        }
        this.loadingService.hideLoading();
      })
    }
  }
}
