import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(
    private router: Router,
    private httpClient: HttpClient
  ) { }

  setGenericSession(value: any, key: string) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getGenericSession(key: string) {
    return localStorage.getItem(key);
  }
}
