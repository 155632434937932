<div class="formEdit">
    <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="data.usrEmail" placeholder="">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput [(ngModel)]="data.usrPassword" placeholder="">
    </mat-form-field>
    <mat-checkbox [(ngModel)]="data.usrEnable">Abilitato</mat-checkbox>
    <button mat-raised-button color="primary" (click)="saveData()">SALVA</button>
    <br/>
    <button mat-raised-button (click)="close()">Annulla e chiudi</button>
</div>
