export class AnaAnagrafica {
    anaId: number;
    anaRagioneSociale: string;
    anaName: string;
    anaSurname: string;
    anaAddress: string;
    anaPiva: string;
    anaCodiceFiscale: string;
    anaCap: string;
    anaProvincia: string;
    anaComune: string;
    anaLastModify: Date;
    anaEmail: string;
    anaTelefono: string;
}