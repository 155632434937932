<div class="formEdit">
    <mat-form-field appearance="fill">
        <mat-label>Attrezzo</mat-label>
        <input matInput [(ngModel)]="data.attAttrezzatura" placeholder="">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Costo</mat-label>
        <input matInput [(ngModel)]="data.attCostoOrario" type="number" placeholder="">
    </mat-form-field>
    <mat-radio-group [(ngModel)]="data.attTipoAttrezzatura">
        <mat-radio-button [value]="3">Km/GG</mat-radio-button>
        <mat-radio-button [value]="2">Ore</mat-radio-button>
        <mat-radio-button [value]="1">Unità</mat-radio-button>
        <mat-radio-button [value]="0">Litri</mat-radio-button>
      </mat-radio-group> 
    <button mat-raised-button color="primary" (click)="saveData()">SALVA</button>
    <br/>
    <button mat-raised-button (click)="close()">Annulla e chiudi</button>
</div>
