import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EditUtenteComponent } from '../modals/edit-utente/edit-utente.component';
import { UsrUser } from '../objects/UsrUser';
import { ApiService } from '../services/api.service';
import { LoadingService } from '../services/loading.service';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-utenti',
  templateUrl: './utenti.component.html',
  styleUrls: ['./utenti.component.scss']
})
export class UtentiComponent implements OnInit, AfterViewInit {

  users: UsrUser[] = [];

  displayedColumns: string[] = ['email', 'password', 'enable', 'actions'];
  dataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private loadingService: LoadingService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private snackBarService: SnackbarService
  ) {
    this.loadingService.showLoading();
    this.getAllUsers();
   }

  ngOnInit(): void {
  }

  getAllUsers() {
    this.apiService.getAllUsers()
    .then((users: UsrUser[]) => {
      this.users = users.filter(x => x.usrType != 0);
      console.log(this.users)
      this.loadingService.hideLoading();
      this.dataSource = new MatTableDataSource(this.users);
      this.dataSource.paginator = this.paginator;      
    })
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.users);
    this.dataSource.paginator = this.paginator;
  }

  addElement() {
    let user: UsrUser = new UsrUser();
    const dialogRef = this.dialog.open(EditUtenteComponent, {
      width: '80%',
      // data: {name: this.name, animal: this.animal}
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
    });
  }

  editElement(user: UsrUser){
    const dialogRef = this.dialog.open(EditUtenteComponent, {
      width: '80%',
      // data: {name: this.name, animal: this.animal}
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
    });
  }

  deleteElement(user: UsrUser){
    if(confirm('Sei sicuro? Azione irreversibile, le missioni di questo utente saranno resettate')){
      this.loadingService.showLoading();
      this.apiService.deleteUser(user)
      .then(x => {
        if(x){
          this.getAllUsers();
          this.snackBarService.openSnackBar("Operazione eseguita con successo")
        } else {
          this.snackBarService.openSnackBar("Ops, qualcosa è andato storto si prega di riprovare")
        }
        this.loadingService.hideLoading();
      })
    }
  }

}
