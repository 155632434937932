<div class="loginContent">
    <div class="loginForm">
        <mat-card>
            <img src="../../assets/images/logo.jpg" width="150">
            <br/>
            <h3>Inserisci le credenziali per accedere</h3>
            <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input matInput [(ngModel)]="email" placeholder="email">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Password</mat-label>
                <input matInput [(ngModel)]="password" type="password" placeholder="password">
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="doLogin()">ACCEDI</button>
        </mat-card>
    </div>
</div>