<div class="formEdit">
    <!-- {{appFleet | json}} -->
    <div>
        <h2>{{data.misCustomerName}} intervento del {{data.misDateStart | date:'EEEE dd-MM-yyyy'}}<br/>
        <div *ngIf="appFleet.rapRapportini">Report salvato il {{appFleet.rapRapportini.rapDataIntervento | date: 'dd-MM-yy HH:mm:ss'}}</div>
    </h2>
    </div>
    <button mat-raised-button (click)="calcolaCosti()" style="width: 240px;" color="primary">CALCOLA COSTI</button>
    <div class="costi" *ngIf="showSomma">
        <label *ngFor="let item of this.appFleet.rapdescRapportiniDescrizioni">{{item.rapdescOperatore}} ({{item.rapdescCostoOrario | currency: 'EUR'}} x {{item.rapdescOre}}) {{item.rapdescSomma | currency: 'EUR'}}<br/></label>
        <br>
        <label *ngFor="let item of this.appFleet.rapAttRapportiniAttrezzature">{{item.rapattAttrezzatura}} ({{item.rapattCostoOrario | currency: 'EUR'}} x {{item.rapattRapUnita}}) {{item.rapattSomma | currency: 'EUR'}}<br/></label>
        <br/>
        <label><b>SOMMA:</b> {{sommaCalcoloCosti | currency: 'EUR'}} + IVA</label>
        <br> 
        <label *ngIf="sommaCalcoloCostiAttrezzaturaNonTrovata"><br/>Attenzione, attrezzatura non più nel programma (rimossa o modificata), impossibile calcolare le suddette:<br/><b> {{sommaCalcoloCostiAttrezzaturaNonTrovata}}</b></label>
    </div>
    <br/><button mat-raised-button (click)="hideCosti()" *ngIf="showSomma" style="width: 240px;" color="primary">NASCONDI COSTI</button>
    <h1>Operatori</h1>
    <mat-form-field appearance="fill">
        <mat-label>Aggiungi operatore</mat-label>
        <mat-select (selectionChange)="onDipChange($event)">
            <mat-option *ngFor="let dip of dipendenti" [value]="dip">{{dip.dipCognome}} {{dip.dipNome}}</mat-option>
        </mat-select>
    </mat-form-field> 
    <div class="operatoreDescInsert" *ngFor="let rapDesc of appFleet.rapdescRapportiniDescrizioni">
        <label>{{rapDesc.rapdescOperatore}}</label>
        <mat-form-field appearance="fill">
            <mat-label>Descrizione intervento</mat-label>
            <input matInput placeholder="" [(ngModel)]="rapDesc.rapdescDescrizione">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Ore</mat-label>
            <input matInput type="number" placeholder="" [(ngModel)]="rapDesc.rapdescOre">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Note</mat-label>
            <input matInput placeholder="" [(ngModel)]="rapDesc.rapdescNote">
        </mat-form-field>
        <button mat-raised-button color="warn" (click)="removeDip(rapDesc)"><mat-icon>close</mat-icon>RIMUOVI</button>
    </div>
    <br/>
    <mat-divider></mat-divider>
    <h1>Attrezzature</h1>
    <mat-form-field appearance="fill">
        <mat-label>Aggiungi attrezzatura</mat-label>
        <mat-select (selectionChange)="onAttChange($event)">
            <mat-option *ngFor="let att of attrezzature" [value]="att">{{att.attAttrezzatura}}</mat-option>
        </mat-select>
    </mat-form-field> 
    <!-- 
    litri: 0,
    unita: 1,
    ore: 2 
    -->
    <div class="operatoreDescInsert" *ngFor="let rapAtt of appFleet.rapAttRapportiniAttrezzature">
        <label>{{rapAtt.rapattAttrezzatura}}</label>
        <!-- {{rapAtt.rapattRapType}} -->
        <mat-form-field *ngIf="rapAtt.rapattRapType === 3" appearance="fill">
            <mat-label>Km</mat-label>
            <input matInput placeholder="" type="number" [(ngModel)]="rapAtt.rapattRapUnita">
        </mat-form-field>
        <mat-form-field *ngIf="rapAtt.rapattRapType === 2" appearance="fill">
            <mat-label>Ore</mat-label>
            <input matInput type="number" placeholder="" [(ngModel)]="rapAtt.rapattRapUnita">
        </mat-form-field>
        <mat-form-field *ngIf="rapAtt.rapattRapType === 0" appearance="fill">
            <mat-label>Litri</mat-label>
            <input matInput placeholder="" type="number" [(ngModel)]="rapAtt.rapattRapUnita">
        </mat-form-field>
        <mat-form-field *ngIf="rapAtt.rapattRapType === 1" appearance="fill">
            <mat-label>N°</mat-label>
            <input matInput placeholder="" type="number" [(ngModel)]="rapAtt.rapattRapUnita">
        </mat-form-field>
        <button mat-raised-button color="warn" (click)="removeAtt(rapAtt)"><mat-icon>close</mat-icon>RIMUOVI</button>
    </div>
    <br/>
    <mat-divider></mat-divider>
    <h1>Articoli</h1>
    <button mat-raised-button (click)="addArtUm()" style="width: 240px;" color="primary"><mat-icon>add</mat-icon> AGGIUNGI ARTICOLO</button>
    <div class="operatoreDescInsert" *ngFor="let rapAtt of appFleet.rapumRapportiniUm">
        <mat-form-field appearance="fill" style="width: 80px;">
            <mat-label>U.M</mat-label>
            <input matInput placeholder="" [(ngModel)]="rapAtt.rapumUm">
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 50px;">
            <mat-label>Q.tà</mat-label>
            <input matInput type="number" placeholder="" [(ngModel)]="rapAtt.rapumQta">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Cod. Articolo</mat-label>
            <input matInput placeholder="" [(ngModel)]="rapAtt.rapumCodArticolo">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Descrizione</mat-label>
            <input matInput placeholder="" [(ngModel)]="rapAtt.rapumDescrizione">
        </mat-form-field>
        <button mat-raised-button color="warn" (click)="removeArt(rapAtt)"><mat-icon>close</mat-icon>RIMUOVI</button>
    </div>
    <br/><br/>
    <button mat-raised-button color="primary" (click)="saveData()"><mat-icon>save</mat-icon> SALVA</button>
    <br/>
    <button mat-raised-button (click)="close()">ANNULLA E CHIUDI</button>
    <br/><br/>
</div>

