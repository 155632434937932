import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttAttrezzatura } from 'src/app/objects/AttAttrezzatura';
import { DipDipendente } from 'src/app/objects/DipDipendente';
import { MisMissioni } from 'src/app/objects/MisMissioni';
import { RapRapportiniFleet } from 'src/app/objects/RapportiniFleet';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import jsPDF from 'jspdf';
import { RapdescRapportiniDescrizioni } from 'src/app/objects/RapdescRapportiniDescrizioni';
import { RapAttRapportiniAttrezzature } from 'src/app/objects/RapAttRapportiniAttrezzature';
import { RapumRapportiniUm } from 'src/app/objects/RapumRapportiniUm';
import { RapportiniToSend } from 'src/app/objects/RapportiniToSend';

@Component({
  selector: 'app-printconvalida',
  templateUrl: './printconvalida.component.html',
  styleUrls: ['./printconvalida.component.scss']
})
export class PrintconvalidaComponent implements OnInit {

  appFleet: RapRapportiniFleet = new RapRapportiniFleet();
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  emailToSend = "";
  mission: MisMissioni

  constructor(
    public dialogRef: MatDialogRef<PrintconvalidaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loadingService: LoadingService,
    private apiService: ApiService,
    private snackBarService: SnackbarService,
    private datePipe: DatePipe
    ) {
      this.loadingService.showLoading();
      this.appFleet.rapAttRapportiniAttrezzature = [];
      this.appFleet.rapdescRapportiniDescrizioni = [];
      this.appFleet.rapumRapportiniUm = [];
      
      this.emailToSend = this.data[1];
      this.mission = this.data[0];

      // debugger;

      if(this.mission.misRapId != null){
        this.apiService.getRapportiniFleetById(this.mission.misId)
        .then((rapFleet: RapRapportiniFleet) => {
          console.log('rapFleet: ', rapFleet);
          this.appFleet = rapFleet;
          
          while(this.appFleet.rapdescRapportiniDescrizioni.length < 6){
            const tmprapdescRapportiniDescrizioni: RapdescRapportiniDescrizioni = new RapdescRapportiniDescrizioni();
            tmprapdescRapportiniDescrizioni.rapdescOperatore = " ";
            tmprapdescRapportiniDescrizioni.rapdescOre = null;
            tmprapdescRapportiniDescrizioni.rapdescNote = " ";
            tmprapdescRapportiniDescrizioni.rapdescDescrizione = " ";
            this.appFleet.rapdescRapportiniDescrizioni.push(tmprapdescRapportiniDescrizioni)
          }
          
          while(this.appFleet.rapAttRapportiniAttrezzature.length < 7){
            const tmprapAttRapportiniAttrezzature: RapAttRapportiniAttrezzature = new RapAttRapportiniAttrezzature();
            tmprapAttRapportiniAttrezzature.rapattAttrezzatura = " ";
            tmprapAttRapportiniAttrezzature.rapattRapUnita = null;
            this.appFleet.rapAttRapportiniAttrezzature.push(tmprapAttRapportiniAttrezzature)
          }
          
          while(this.appFleet.rapumRapportiniUm.length < 26){
            const rapumRapportiniUm: RapumRapportiniUm = new RapumRapportiniUm();
            rapumRapportiniUm.rapumUm = " ";
            rapumRapportiniUm.rapumDescrizione = " ";
            this.appFleet.rapumRapportiniUm.push(rapumRapportiniUm)
          }

          this.loadingService.hideLoading();
        });
      }
  }

  ngOnInit(): void {}

  openPDF(): void {
    const DATA = this.pdfTable.nativeElement;
    const doc: jsPDF = new jsPDF("p", "px", "a4", true)
    doc.setProperties({
      title: 'CONVALIDA',
      subject: 'PDF',
      author: 'GARDENZOO',
      keywords: 'pdf, gardenzoo, generated',
      creator: 'Applika software for GardenZoo'
    });
    doc.html(DATA, {
       callback: (doc) => {
          // doc.autoPrint();
          doc.output("dataurlnewwindow");
          var blob = doc.output('datauri');
          console.log(blob);
         this.closeDialog();
       }
    });
  }

  send() {
    // inviare via email il blob mediante api e convertirlo PDF
    if(confirm('Confermi? Questa operazione è irreversibile. \nSei sicuro di voler inviare al cliente il report in formato PDF mediante Email?')){
      this.loadingService.showLoading();
      const DATA = this.pdfTable.nativeElement;
      const doc: jsPDF = new jsPDF("p", "px", "a4", true)
      doc.setProperties({
        title: 'CONVALIDA',
        subject: 'PDF',
        author: 'GARDENZOO',
        keywords: 'pdf, gardenzoo, generated',
        creator: 'Applika software for GardenZoo'
      });
      doc.html(DATA, {
         callback: (doc) => {
            // doc.autoPrint();
            // doc.output("dataurlnewwindow");
            var blob = doc.output('datauri');
            // console.log(blob);
            let reportToSend: RapportiniToSend = new RapportiniToSend();
            reportToSend.base64 = JSON.stringify(blob);
            reportToSend.emailToSend = this.emailToSend;
            reportToSend.missionId = this.mission.misId;
            console.log('reportToSend: ', reportToSend)
            this.apiService.sendReport(reportToSend)
            .then(ret => {
              console.log('RET send report: ', ret);
              if(ret){
                this.snackBarService.openSnackBar("Operazione eseguita con successo.")   
                this.closeDialog();             
              } else {
                this.snackBarService.openSnackBar("Ops, qualcosa è andato storto");
              }
              this.loadingService.hideLoading();
            })
         }
      });
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
